export const lightColors = {
    Primary: '#FFFFFF',
    Secondary: '#000000',
    color1: '#787878',
    color2: '#d3d3d3',
    color3: '#0033FF'
}

export const darkColors = {
    Primary: '#000000',
    Secondary: '#FFFFFF',
    color1: '#787878',
    color2: '#404040',
    color3: '#0033FF'
}

export const PRIMARY = '#8d448b'
export const SECONDARY = '#8A8CBD'
export const WHITE = '#FFFFFF'
export const BLACK = '#000000'
export const GREY = '#D9D9D9'
export const LAVENDER_MIST = '#E7E0EC'
export const DARK_GREY = '#B1B1B1'
export const GREEN = '#00B300'
export const ZAMBEZI = '#695C5C'
export const SOFT_PEACH = '#D9F2D9'
export const RED = '#FF0000'
export const LIGHT_GREY = '#D3D3D3'
export const DARK_RED = '#DF2121'
export const SUVA_GREY = '#8A8A8A'
export const VANiLLA_ICE = '#edd3d3'